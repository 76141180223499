var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.license != null
    ? _c(
        "section",
        [
          _vm.license != null && _vm.user.information.is_admin == 2
            ? _c(
                "b-nav",
                { attrs: { tabs: "" } },
                [
                  _c(
                    "b-nav-item",
                    {
                      attrs: { to: { name: "Administration.Billing.Reports" } },
                    },
                    [_vm._v(_vm._s(_vm.$t("BILLING.REPORTS")))]
                  ),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { to: { name: "Administration.Billing.Service" } },
                    },
                    [_vm._v(_vm._s(_vm.$t("BILLING.SERVICE")))]
                  ),
                  _vm.showNodes
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            to: { name: "Administration.Billing.Node" },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("BILLING.NODE")))]
                      )
                    : _vm._e(),
                  _vm.showPartnerBilling
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            to: { name: "Administration.Billing.Partner" },
                          },
                        },
                        [_vm._v("\n      " + _vm._s(_vm.$t("BILLING.PARTNER")))]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form",
            { staticClass: "mt-2", attrs: { inline: "" } },
            [
              _c(
                "b-button",
                { staticClass: "no-border", on: { click: _vm.refreshView } },
                [_c("i", { staticClass: "fal fa-sync" })]
              ),
              _c(
                "AddReport",
                {
                  key: _vm.createdReportKey,
                  attrs: { license: _vm.license },
                  on: { createdReport: _vm.createdReport },
                },
                [
                  _c(
                    "b-btn",
                    { staticClass: "inlineBtn", attrs: { variant: "dark" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("BILLING.ADD_REPORT")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("MonthPicker", {
                staticClass: "ml-2",
                attrs: { previousMonth: "" },
                on: { change: _vm.refreshView },
                model: {
                  value: _vm.from_date,
                  callback: function ($$v) {
                    _vm.from_date = $$v
                  },
                  expression: "from_date",
                },
              }),
              _c("b-form-input", {
                staticClass: "searchText newline-mobile",
                attrs: { trim: "", placeholder: _vm.$t("SEARCH") },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                  },
                },
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText",
                },
              }),
              _c(
                "b-form-select",
                {
                  staticClass: "limit",
                  attrs: { name: "billing_limit" },
                  model: {
                    value: _vm.limit,
                    callback: function ($$v) {
                      _vm.limit = $$v
                    },
                    expression: "limit",
                  },
                },
                [
                  _c("b-form-select-option", { attrs: { value: "10" } }, [
                    _vm._v("10"),
                  ]),
                  _c("b-form-select-option", { attrs: { value: "25" } }, [
                    _vm._v("25"),
                  ]),
                  _c("b-form-select-option", { attrs: { value: "45" } }, [
                    _vm._v("45"),
                  ]),
                  _c("b-form-select-option", { attrs: { value: "100" } }, [
                    _vm._v("100"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.from_date
            ? _c("router-view", {
                key: _vm.refreshKey,
                attrs: {
                  limit: _vm.limit,
                  searchText: _vm.searchText,
                  from_date: _vm.from_date,
                  license: _vm.license,
                },
                on: {
                  "update:limit": function ($event) {
                    _vm.limit = $event
                  },
                  "update:searchText": function ($event) {
                    _vm.searchText = $event
                  },
                  "update:search-text": function ($event) {
                    _vm.searchText = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }